<template>
  <div>
    <h2>User Register Progress WIP</h2>
  </div>
</template>
<script>
export default {
  name: 'USER_REGISTER_PROGRESS',
}
</script>
